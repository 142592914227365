export function init() {
			
	var cols = ".column";
	if ($(window).width() < 960) { cols = ".one-half, .one-third, .two-third"; }

	$('.column-section.adapt-height').each(function() { 
		var thisEl = $(this);
		$(thisEl).children(".column").css('minHeight','inherit');
		$(thisEl).children(".column").find(".col-content").css('marginTop', '0');
						
		if ($(window).width() > 767) {
			var maxHeight = 0;
			var tallestEl = '';
			$(thisEl).children(cols).each(function() {
				var theHeight = $(this).outerHeight();
				var theBorder = parseInt($(this).css('border-top-width'), 10) + parseInt($(this).css('border-bottom-width'), 10);
				if (theHeight + theBorder > maxHeight) { maxHeight = theHeight + theBorder+1; tallestEl = $(this); }
				// +1 is hack for bordered sticky
			});
			if (maxHeight) {
				$(thisEl).children(".column").css('minHeight',maxHeight+'px');
				$(tallestEl).addClass("tallest");	
			}
			
			// apply vertical-center
			if ($(thisEl).hasClass("vertical-center")) {
				$(thisEl).children(".column:not(.tallest)").each(function() {
					if ($(this).find(".col-content").length > 0 && !$(this).find(".col-content").is(':empty')) {
						var theContent = $(this).find(".col-content");
						var elHeight = maxHeight - (parseInt($(this).css('paddingTop'), 10) + parseInt($(this).css('paddingBottom'), 10));
						var contentHeight = $(theContent).height();
						if (contentHeight < elHeight) { 
							var centerMargin = (elHeight - (contentHeight)) / 2;
							$(theContent).css('marginTop', centerMargin + 'px');
						}
					} 
				});
			}
		}
	});
	
	/* Adapt Fullheight Section */
	if ($(".fullwidth-section.fullheight").length > 0) {
		$(".fullwidth-section.fullheight").each(function() { 
			var theContent = $(this).find(".fullwidth-content");
			var contentHeight = $(theContent).height();
			var contentPadding = parseInt($(theContent).css('paddingTop')) + parseInt($(theContent).css('paddingBottom'), 10);
			if (contentHeight+contentPadding <= $(this).height()) { 
				var centerMargin = ($(this).height() - (contentHeight+contentPadding)) / 2;
				$(theContent).css('transform', 'translateY(' + centerMargin + 'px)');
			}
		});
	}

	/* Adapt fullscreenView section */
	if($('.fullscreen-view').length) {
		if($(window).width() > 1024) {
			var viewportHalfHeight = ($(window).height() - $('#header').height()-2) / 2;
			$('.fullscreen-center-caption').css('top',viewportHalfHeight+10);
		} else {
			var viewportHalfHeight = ($(window).height() - $('#header').height()+60) / 2;
		}

		$('.fullscreen-item').height(viewportHalfHeight);
	}
}