import '../helpers/helpers';

import * as slickSliders from '../functions/slickSliders';
// import * as googleMap from '../functions/googleMap';
import * as templateScripts from '../functions/templateScripts';
// import * as openVideo from '../functions/openVideo';
import * as adaptHeight from '../functions/adaptHeight';
import * as Powertip from 'powertip';
import * as datepicker from 'datepicker';

// import * as skrollr from 'skrollr';

document.addEventListener('DOMContentLoaded', function(){

	var $fancybox = $('a.fancybox'),
		$fancyboxThumbs = $('a.fancybox-thumbs'),
		$map = document.getElementById('map'),
		$powertip = document.querySelectorAll('.powertip'),
		$playVideo = document.querySelector('.play-video'),
		$mainSlider = document.querySelectorAll('.main-slider'),
		isFirefox = typeof InstallTrigger !== 'undefined',
		isIE = /*@cc_on!@*/false || !!document.documentMode,
		isEdge = !isIE && !!window.StyleMedia;

	templateScripts.init();

	if($mainSlider){
		slickSliders.init();
	}

	if($fancybox.length) {
		$fancybox.fancybox({
			helpers: {
				overlay: {
					locked: false
				}
			}
		});
	}

	if($fancyboxThumbs.length) {
		$fancybox.fancybox({
			afterLoad: function(){
                $('body').addClass('body-fancyboxed');
            },
            beforeClose: function(){
                $('body').removeClass('body-fancyboxed');
            },
			helpers: {
				thumbs: {
					width: 130,
					height: 75
				},
				overlay: {
					locked: false
				}
			}
		});
	}

	// if($map) {
	// 	googleMap.init();
	// }

	// if($playVideo) {
	// 	openVideo.init();
	// }

	if($('.page-gallery').length || $('.gallery-big-hld').length) {
		$('#header').addClass('header-style-vertical');
	}

	$('.close-btn, .popup-blank').on('click', function(){
		$('.popup-hld').removeClass('js-show');
	});

	if(isFirefox){
		$('html').addClass('mozilla');
	}

	$('.bon-turystyczny-hld .icon-close, .ihg-clean-hld .icon-close').on('click', function(){
		$(this).parent().fadeOut();
	});

	if($powertip) {
		$('.powertip').powerTip({
			placement: 's',
			smartPlacement: true,
			offset: -30,
			fadeInTime: 100,
			fadeOutTime: 75
		});
	}

	if(isIE || isEdge) {
		$('html').addClass('ie');	
	}

	document.querySelectorAll('.wpcf7 input[name="page-url"]').forEach(function(element) { element.value = window.location.href; });

	$('#resform .from').datepicker({
        dateFormat: 'yy-mm-dd',
        onSelect: function(da, obj) {
            var spl = da.split(/-/);
            var month = spl[1];
            
            month--;

            if(month < 10) {
                month = '0' + month;
            }
            $('#resform .qCiD').val(spl[2]);
            $('#resform .qCiMy').val(month + '' + spl[0]);

            var min_date = new Date(da);
            $('#resform .to').datepicker('option', 'minDate', min_date);
        },
        minDate: new Date(),
    });

    $('.reservation-online').on('click', function(e){
    	e.preventDefault();

    	$('.booking-panel-hld').slideDown();
    });

    $('.booking-panel-hld .close').on('click', function(){
    	$('.booking-panel-hld').slideUp();
    });

    $("#resform .from").datepicker("setDate", new Date());

    $('#resform .to').datepicker({
        dateFormat: 'yy-mm-dd',
        onSelect: function(da, obj) {
            var spl = da.split(/-/);
            var month = spl[1];
            
            month--;

            if(month < 10) {
                month = '0' + month;
            }
            $('#resform .qCoD').val(spl[2]);
            $('#resform .qCoMy').val(month + '' + spl[0]);
        }
    });

    var tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);

    $("#resform .to").datepicker("setDate", tomorrow);

    $(".sub-menu").each(function(){
	    var subMenu = $(this);

	    if(subMenu.has('.sub-menu').length) {
	       subMenu.addClass('has-sub-menu');
	    }
	});

	[].forEach.call(document.querySelectorAll('img[data-src]'), function(img) {
	  img.setAttribute('src', img.getAttribute('data-src'));
	  img.onload = function() {
	    img.removeAttribute('data-src');
	  };
	});

	$('#menu .menu').find('.menu-item-has-children a').on('click', function(e){
		if($(this).parent().hasClass('menu-item-has-children')) {
			e.preventDefault();
			e.stopPropagation();
		}

		$(this).parent().toggleClass('active');

		$(this).parent().find('.sub-menu').first().slideToggle();
	});

    if($('.popup-hld--startpage')) {
		$('.popup-hld--startpage').addClass('js-show');
	}
	// if(localStorage.getItem('popState') != 'shown'){
 //        localStorage.setItem('popState','shown')
 //    }
});

function checkViewPort() {
	if($(window).width() > 1024 && $('html').hasClass('mobile-view')){
		$('html').removeClass('mobile-view');
	} else if ($(window).width() <= 1024 && !$('html').hasClass('mobile-view')){
		$('html').addClass('mobile-view');
	}
}

$(window).on('load', function() {
	var $adaptHeight = $('.column-section.adapt-height'),
		$fullscreenView = $('.fullscreen-view'),
		$videobg = document.querySelector('.videobg');

	checkViewPort();
	
	if($adaptHeight.length || $fullscreenView.length) {
		adaptHeight.init();
	}

	if($videobg) { 
		$('.videobg').bgVideo();
	}

	// if (!Modernizr.csspointerevents && !Modernizr.touchevents) {
	//   var mySkrollr = skrollr.init({
	// 	edgeStrategy: 'set',
	// 	smoothScrolling: false,
	// 	forceHeight: false
	// 	});
	// }
});

$(window).resize(function() { 
	var $adaptHeight = $('.column-section.adapt-height'),
		$fullscreenView = $('.fullscreen-view');

	if($adaptHeight.length || $fullscreenView.length) {
		adaptHeight.init();
	}

	checkViewPort();

	if($(window).width() > 1023) { $('#menu').removeClass('menu-is-open'); }
});

AOS.init({
	disable: 'mobile',
	easing: 'ease-out',
	duration: 1000
});