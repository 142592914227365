export function init() {

	var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

	var $mainSlider = document.getElementById('banner-slider'),
		slideWrapper = $("#banner-slider"),
		iframes = slideWrapper.find('.embed-player'),
		$carousel = document.querySelector('.carousel'),
		$stickyCarousel = document.querySelector('.sticky-carousel'),
		$spacedCarousel = document.querySelector('.spaced-carousel'),
		$testimonialsSlider = document.querySelector('.testimonials-slider'),
		$galleryBig = document.querySelector('.gallery-big'),
		$sliderBox = document.querySelector('.slider-box'),
		$sliderBox2 = document.querySelector('.slider-box2'),
		$sliderBox3 = document.querySelector('.slider-box3'),
		$sliderBox4 = document.querySelector('.slider-box4'),
		$sliderBox5 = document.querySelector('.slider-box5'),
		$sliderBox6 = document.querySelector('.slider-box6'),
		$galleryWithSubtitle = document.querySelector('.gallery-with-subtitle--1'),
		$galleryWithSubtitle2 = document.querySelector('.gallery-with-subtitle--2'),
		$brandsSlider = document.querySelector('.brands-slider'),
		lazyImages = slideWrapper.find('.banner-img'),
		lazyCounter = 0;

	// POST commands to YouTube or Vimeo API
	function postMessageToPlayer(player, command){
	  if (player == null || command == null) return;
	  player.contentWindow.postMessage(JSON.stringify(command), "*");
	}

	// When the slide is changing
	function playPauseVideo(slick, control){
	  var currentSlide, slideType, startTime, player, video;

	  currentSlide = slick.find(".slick-current");
	  slideType = currentSlide.find('.banner-item').attr("class").split(" ")[1];
	  player = currentSlide.find("iframe").get(0);
	  startTime = currentSlide.data("video-start");

	  if (slideType === "vimeo") {
	    switch (control) {
	      case "play":
	        if ((startTime != null && startTime > 0 ) && !currentSlide.hasClass('started')) {
	          currentSlide.addClass('started');
	          postMessageToPlayer(player, {
	            "method": "setCurrentTime",
	            "value" : startTime
	          });
	        }
	        postMessageToPlayer(player, {
	          "method": "play",
	          "value" : 1
	        });
	        break;
	      case "pause":
	        postMessageToPlayer(player, {
	          "method": "pause",
	          "value": 1
	        });
	        break;
	    }
	  } else if (slideType === "youtube") {
	    switch (control) {
	      case "play":
	        postMessageToPlayer(player, {
	          "event": "command",
	          "func": "mute"
	        });
	        postMessageToPlayer(player, {
	          "event": "command",
	          "func": "playVideo"
	        });
	        break;
	      case "pause":
	        postMessageToPlayer(player, {
	          "event": "command",
	          "func": "pauseVideo"
	        });
	        break;
	    }
	  } else if (slideType === "video") {
	    video = currentSlide.find('.banner-item').children("video").get(0);

	    if (video != null) {
	      if (control === "play"){
	      	var promise = document.querySelector('video').play();

			if (promise !== undefined) {
			  promise.then(_ => {
			    // Autoplay started!
			  }).catch(error => {
			    // Autoplay was prevented.
			    // Show a "Play" button so that user can start playback.

			    currentSlide.find('.play-video').fadeIn();

			    currentSlide.find('.play-video').on('click',function(){
			    	video.play();
			    	
		      		$(this).fadeOut();
		      	});
			  });
			}
	      	// if(isChrome) {

	      	// 	if (typeof navigator.brave !== 'undefined') {
	      	// 		currentSlide.find('.play-video').fadeOut();
			     //    video.play();
	      	// 	} else {
	      	// 		currentSlide.find('.play-video').on('click',function(){
			     //  		video.play();
			      		
			     //  		$(this).fadeOut();
			     //  	});
	      	// 	}
	      	// } 
	      } else {
	        video.pause();
	      }
	    }
	  }
	}

	// Resize player
	function resizePlayer(iframes, ratio) {
	  if (!iframes[0]) return;
	  var win = $(".main-slider"),
	      width = win.width(),
	      playerWidth,
	      height = win.height(),
	      playerHeight,
	      ratio = ratio || 16/9;

	  iframes.each(function(){
	    var current = $(this);
	    if (width / ratio < height) {
	      playerWidth = Math.ceil(height * ratio);
	      current.width(playerWidth).height(height).css({
	        left: (width - playerWidth) / 2,
	         top: 0
	        });
	    } else {
	      playerHeight = Math.ceil(width / ratio);
	      current.width(width).height(playerHeight).css({
	        left: 0,
	        top: (height - playerHeight) / 2
	      });
	    }
	  });
	}

	// DOM Ready
	$(function() {
	  // Initialize
	  slideWrapper.on("init", function(slick){
	    slick = $(slick.currentTarget);
	    setTimeout(function(){
	      playPauseVideo(slick,"play");
	    }, 1000);
	    resizePlayer(iframes, 16/9);
	  });
	  slideWrapper.on("beforeChange", function(event, slick) {
	    slick = $(slick.$slider);
	    playPauseVideo(slick,"pause");
	  });
	  slideWrapper.on("afterChange", function(event, slick) {
	    slick = $(slick.$slider);
	    playPauseVideo(slick,"play");
	  });
	  slideWrapper.on("lazyLoaded", function(event, slick, image, imageSource) {
	    lazyCounter++;
	    if (lazyCounter === lazyImages.length){
	      lazyImages.addClass('show');
	      // slideWrapper.slick("slickPlay");
	    }
	  });

	  if($mainSlider) {
		  //start the slider
		  slideWrapper.slick({
		    // fade:true,
		    autoplay: true,
		    autoplaySpeed:5000,
		    lazyLoad:"progressive",
		    speed:1000,
		    arrows: true,
		    infinite: true,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
		    dots:false,
		    slidesToShow: 1,
		    pauseOnFocus: false,
		    pauseOnHover: false,
		    useTransform: true,
		    cssEase:"cubic-bezier(0.87, 0.03, 0.41, 0.9)"
		  });
		}
	});

	$(window).on("resize.slickVideoPlayer", function(){  
	  resizePlayer(iframes, 16/9);
	});

	// if($mainSlider) {

		// if($mainSlider.hasClass('banner-slider--arrows') && $('.banner-item').length > 1){
		// 	$('.banner-slider--arrows').slick({
		// 		infinite: true,
		// 		speed: 1000,
		// 		slidesToShow: 1,
		// 		autoplay: true,
		// 		autoplaySpeed: 5000,
		// 		fade: true,
		// 		useTransform: true,
		// 		cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
		// 		dots: false,
		// 		arrows: true,
		// 		prevArrow: '<span class="slick-prev"></span>',
		// 		nextArrow: '<span class="slick-next"></span>'
		// 	});
		// } else if($mainSlider.hasClass('banner-slider--dots') && $('.banner-item').length > 1) {
		// 	$('.banner-slider--dots').slick({
		// 		infinite: true,
		// 		speed: 1000,
		// 		slidesToShow: 1,
		// 		autoplay: true,
		// 		autoplaySpeed: 5000,
		// 		fade: true,
		// 		useTransform: true,
		// 		cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
		// 		dots: true,
		// 		arrows: false
		// 	});
		// }
	// }

	if($carousel) {
		$('.carousel').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 5,
			slidesToScroll: 3,
			autoplay: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: false,
			responsive: [
		    {
		      	breakpoint: 1024,
		      	settings: {
			        slidesToShow: 3,
			        slidesToScroll: 3,
			        infinite: true,
			        dots: true
		      	}
		   	},
		    {
		      	breakpoint: 480,
		      	settings: {
			        slidesToShow: 2,
			        slidesToScroll: 2
		      	}
		    }]
		});
	}

	if($stickyCarousel) {
		$('.sticky-carousel').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 4,
			slidesToScroll: 1,
			autoplay: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: true,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			responsive: [
		    {
		      	breakpoint: 1024,
		      	settings: {
			        slidesToShow: 3,
			        slidesToScroll: 3,
			        infinite: true,
			        dots: true
		      	}
		   	},
		    {
		      	breakpoint: 480,
		      	settings: {
			        slidesToShow: 1,
			        slidesToScroll: 1
		      	}
		    }]
		});
	}

	if($spacedCarousel) {
		$('.spaced-carousel').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 4,
			slidesToScroll: 2,
			autoplay: false,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: true,
			arrows: false,
			responsive: [
		    {
		      	breakpoint: 1024,
		      	settings: {
			        slidesToShow: 3,
			        slidesToScroll: 3,
			        infinite: true,
			        dots: true
		      	}
		   	},
		    {
		      	breakpoint: 480,
		      	settings: {
			        slidesToShow: 1,
			        slidesToScroll: 1
		      	}
		    }]
		});
	}

	if($galleryWithSubtitle) {
		$('.gallery-with-subtitle--1').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: false,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			dots: true,
			arrows: true
		});
	}

	if($galleryWithSubtitle2) {
		$('.gallery-with-subtitle--2').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: false,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			dots: true,
			arrows: true
		});
	}

	if($testimonialsSlider) {
		$('.testimonials-slider').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: false,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: true,
			arrows: false
		});
	}

	if($galleryBig) {
		$('.gallery-big').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: true,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>',
			appendArrows: document.querySelector('.gallery-big-arrows'),
			asNavFor: '.gallery-thumbs'
		});

		$('.gallery-thumbs').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 3,
			slidesToScroll: 1,
			autoplay: false,
			useTransform: true,
			centerMode: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: false,
			focusOnSelect: true,
			asNavFor: '.gallery-big',
			responsive: [
		    {
		      	breakpoint: 480,
		      	settings: {
			        slidesToShow: 2,
			        slidesToScroll: 1,
			        centerMode: false
		      	}
		    }]
		});
	}

	if($sliderBox) {
		$('.slider-box').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			fade: true,
			arrows: true,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>'
		});
	}

	if($sliderBox2) {
		$('.slider-box2').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			fade: true,
			arrows: true,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>'
		});
	}

	if($sliderBox3) {
		$('.slider-box3').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			fade: true,
			arrows: true,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>'
		});
	}

	if($sliderBox4) {
		$('.slider-box4').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			fade: true,
			arrows: true,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>'
		});
	}

	if($sliderBox5) {
		$('.slider-box5').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			fade: true,
			arrows: true,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>'
		});
	}

	if($sliderBox6) {
		$('.slider-box6').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			fade: true,
			arrows: true,
			prevArrow: '<span class="slick-prev"></span>',
			nextArrow: '<span class="slick-next"></span>'
		});
	}

	if($brandsSlider) {
		$('.brands-slider').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 10,
			slidesToScroll: 4,
			autoplay: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			arrows: false
		});
	}

}