import 'jquery';
import 'fancybox';
import 'fancyboxThumbs';
import 'slick-carousel';
import 'easing';
import 'jqueryui';
import 'aos';
import 'bgvideo';
import 'visible';
import 'modernizr-custom';

import './functions/initApp';